.projectsWrapper {
  margin-top: 50px;
  display: grid;
  gap: 50px;
  max-width: 1260px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 50px;


  .project{
    animation: scaleProjectAnimation 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      max-width: 100%;
    }
    .title {
      font-size: 30px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .button {
      background-color: transparent;
      border: none;

      font-size: 15px;
      cursor: pointer;
      padding: 7px 15px;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        top: 30%;
        left: -4px;
        border-top: 3px solid #fff;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        border-right: 3px solid #fff;
        transition: 0.4s;
      }
      
      &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: 0.4s;
      }

      &:hover{
        &:before{
          transform: rotate(0);
        }
        &:after{
          width: 0;
        }
      }
    }
  }
}

@media(max-width: 1000px){
  .projectsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@keyframes scaleProjectAnimation {
  0%{
    transform: scale(0);
  }
  100%{
    transform: scale(1);
  }
}