.aboutMe {
  margin-top: 50px;
  margin-bottom: 50px;
  .title{
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .text{
    font-size: 25px;
    animation: opacity 1.2s;
    line-height: 35px;
  }
}

@media (max-width: 500px) {
  .aboutMe{
    margin-top: 35px;
  }
}


@keyframes opacity {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}