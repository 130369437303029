.skills {
  margin-bottom: 50px;
  margin-top: 50px;
  .title {
    font-size: 45px;
    font-weight: bold;
  }
  .skillsList {
    margin-top: 40px;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(5, 1fr);

    .item {
      display: inline-block;

      .link{
        font-size: 50px;
        animation: opacity 1.2s;
      }
      .title{
        font-size: 25px;
        font-weight: normal;
        margin-top: 15px;
        line-height: 50px;
      }


    }


  }
  .tooltip {
    font-size: 20px !important;
  }
}


@media (max-width: 750px) {
  .skills{
    .skillsList {
      grid-template-columns: repeat(4, 1fr);
      gap: 25px;
    }
  }
}

//@media(max-width: 650px){
//  .skills{
//    .skillsList {
//      .item{
//        .title{
//          display: block;
//        }
//      }
//    }
//  }
//}


@media (max-width: 600px) {
  .skills{
    margin-top: 35px;
    .title {
      font-size: 40px;
    }
    .skillsList {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media(max-width: 400px){
  .skills{
    .title {
      font-size: 35px;
    }
    .skillsList {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@keyframes opacity {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}