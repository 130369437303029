.home {
  display: flex;
  justify-content: center;
  margin-top: 125px;
  .information {
    text-align: left;
    margin-right: 50px;
    position: relative;
    overflow: hidden;

    .hello {
      font-size: 115px;
      position: relative;
      animation-name: leftToRightAnimation;
      animation-duration: 1s;
    }

    .name{
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 60px;
      position: relative;
      animation-name: leftToRightAnimation;
      animation-duration: 2s;
    }
    .workspace {
      font-size: 30px;
      position: relative;
      animation-name: leftToRightAnimation;
      animation-duration: 3s;
    }
  }

  .selfiePhoto {
    border-radius: 50px;
    margin-bottom: 25px;
    max-width: 500px;
    animation-name: opacity;
    animation-duration: 3s;
  }
}

@media(max-width: 1000px) {
  .home {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 45px;

    .information {
      margin-bottom: 50px;
      margin-right: 0;

      .hello {
        font-size: 115px;
        position: relative;
        animation-name: leftToRightAnimation;
        animation-duration: 1s;
      }

      .name{
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 60px;
        position: relative;
        animation-name: leftToRightAnimation;
        animation-duration: 2s;
      }
      .workspace {
        font-size: 30px;
        position: relative;
        animation-name: leftToRightAnimation;
        animation-duration: 3s;
      }
    }

    .selfiePhoto {
      border-radius: 50px;
      margin-bottom: 25px;
      max-width: 300px;
      animation-name: opacity;
      animation-duration: 3s;
      height: auto;
    }
  }
}


@keyframes leftToRightAnimation {
  0%{
    left: -100%;
  }
  100%{
    left: 0;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}