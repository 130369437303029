.contact {
  margin-bottom: 50px;
  margin-top: 50px;
  .title{
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .contactForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .formTitle {
      font-size: 35px;
      grid-column: 1/3;
    }


    .formInputWrapper.name{
      grid-column: 1/1;
      }

    }
    .formInputWrapper.email{
      grid-column: 2/3;

    }
    .formInputWrapper.text{
      grid-column: 1/3;
      .formInput{
      }
    }


    .formInputWrapper {
      display: flex;
      flex-direction: column;

      .formInput {
        padding: 10px 25px;
        font-size: 18px;
        color: black;
        transition: 0.3s;
        background-color: rgba(255, 255, 255, 0.75);
        border: 2px solid black;

        &::placeholder {
          color: gray;
        }
        &:focus {
          outline: none;
          transform: scale(1.1);
          background-color: #fff;
        }
    }
  }

  .email{
    font-size: 27px;
    margin-bottom: 20px;
  }

  .socialLinks{
    display: flex;
    justify-content: center;
    align-items: center;
    .socialContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      animation: opacity 1.2s;
      margin-right: 20px;
      &:nth-last-child(1){
        margin-right: 0;
      }
      .socialLink {
        display: flex;
        font-size: 27px;
        transition: 0.4s;

        &:hover{
          transform: scale(1.08);
        }
        .socialTitle{
          font-size: 27px;
          margin-right: 10px;
        }
      }
    }
  }
}




@keyframes opacity {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}