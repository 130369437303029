.projectMore {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  .moreSection {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .image {
      max-width: 100%;
    }

    .title {
      font-size: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .text {
      font-size: 20px;

    }

    .liveCodeButtons {
      margin-top: 20px;
      display: flex;
      justify-content: center;


      .button{
        padding: 10px 25px;
        border: 2.5px solid #4b4b4b;
        margin-right: 25px;
        transition: 0.4s;
        position: relative;
        overflow: hidden;
        
        &:after{
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 100%;
          background-color: #fff;
          transition: 0.4s;

        }

        &:nth-last-child(1){
          margin-right: 0;
        }

        &:hover {
          border: 2.5px solid #fff;

          &:after{
            width: 15%;
          }
        }
      }
    }
  }

  .closeProjectMore{
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    font-size: 50px;
    cursor: pointer;
  }
}