.noMatch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title {
    font-size: 35px;
  }
  .subtitle {
    margin-top: 10px;
    font-size: 25px;
    .homeLink {
      font-size: 40px;
      font-weight: bold;
      position: relative;
      &:hover{
        &:before{
          content: '';
          position: absolute;
          background-color: white;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          animation: navItemAnimation 0.3s;
        }
      }
    }
  }
}

@keyframes navItemAnimation {
  0%{
    width: 0;
  }
  100%{
    width: 100%;
  }
}