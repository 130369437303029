.mainNav {
  margin: 30px 0;

  .hamburger{
    display: none;
    font-size: 50px;
    cursor: pointer;
    position: relative;
    user-select: none;
    appearance: none;
    border: none;
    background: none;
    outline: none;
    left: 0;
    .line{
      display: block;
      width: 30px;
      height: 3px;
      background-color: white;
      margin-bottom: 5px;
      position: relative;
      border-radius: 6px;
      transform-origin: 0 0;
      transition: 0.4s;
    }
    &:hover .line:nth-child(2){
      transform: translateX(10px);
      background-color: #878787;
    }
  }
  .hamburger.isActive .line:nth-child(1){
    transform: translate(0px, -2px) rotate(45deg);
  }
  .hamburger.isActive .line:nth-child(2){
    opacity: 0;
    transform: translateX(15px);
  }
  .hamburger.isActive .line:nth-child(3){
    transform: translate(-3px, 3px) rotate(-45deg);
  }
  .hamburger.isActive{
    &:hover{
      .line{
        background-color: #878787;
      }
    }
  }
  .navList{
    display: flex;
    justify-content: center;
    position: relative;
    right: 0;



    .item{
      margin-left: 25px;

      &:first-child{
        margin-left: 0;
      }

      .link {
        font-size: 25px;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          background-color: white;
          bottom: 0;
          left: 0;
          width: 0;
          height: 2px;
          transition: 0.3s;
        }
        &:hover{
          &:before{
            width: 100%;
          }
        }
      }

      .link.active {
        position: relative;

        &:before{
          content: '';
          position: absolute;
          background-color: white;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          animation: navItemAnimation 0.3s;
        }
      }

    }
  }
}



@media(max-width: 1000px){
  .mainNav {
    position: relative;
    transition: 0.2s;
    &.active{
      margin: 150px 0 170px 0;
    }

    .hamburger {
      display: block;
    }

    .navList{
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: -350%;
      right: -100%;
      transition: 0.5s;
      &.active{
        transform: translateX(50%);
        right: 50%;
      }

      .item{

        margin-left: 0;
        margin-top: 15px;

        &:first-child{
          margin-left: 0;
        }

        .link {
          font-size: 25px;

        }

      }
    }
  }
}

@media(max-width: 400px){
  .mainNav {
    .navList{
      .item{
        .link {
          font-size: 20px;
          &:hover{
            &:before{
              width: 0;
            }
          }
        }


      }
    }
  }

}


@keyframes lineMoveActive {
  0%{
    left: -100%;
  }
  100%{
    left: 0;
  }
}




@-webkit-keyframes mobileMenuAnimation {
  0%{
    right: -100%;
  }
  100%{
    right: 0;
  }
}

@-moz-keyframes mobileMenuAnimation {
  0%{
    right: -100%;
  }
  100%{
    right: 0;
  }
}

@-ms-keyframes mobileMenuAnimation {
  0%{
    right: -100%;
  }
  100%{
    right: 0;
  }
}

@-o-keyframes mobileMenuAnimation {
  0%{
    right: -100%;
  }
  100%{
    right: 0;
  }
}

@keyframes mobileMenuAnimation{
  0%{
    right: -100%;
  }
  100%{
    right: 0;
  }
}


@keyframes navItemAnimation {
  0%{
    width: 0;
  }
  100%{
    width: 100%;
  }
}