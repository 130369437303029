.experience{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  transition: 0.3s;
  .title{
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .experienceTitle{
    margin-bottom: 30px;
    font-size: 22px;
    animation: opacity 1s;
  }
}

@keyframes opacity {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

