.container {
  max-width: 1260px;
  margin: auto;
  padding-bottom: 50px;
}

.home {
  overflow: hidden;
  position: relative;
  padding-left: 80px;
  padding-right: 80px;
  background-color: rgba(0, 0, 0, 0.23);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
}

@media(max-width: 700px) {
  .home {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media(max-width: 350px) {
  .home {
    padding-left: 10px;
    padding-right: 10px;
  }
}